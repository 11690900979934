import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import pageOne from './Pages/pageOne';
// import PageTwo from './Pages/PageTwo';

function App() {
  return (
    <Router>
    <Routes>
      <Route path='/' Component={pageOne}></Route>
      {/* <Route path='/s' Component={PageTwo}></Route> */}
    </Routes>
    </Router>
  );
}

export default App;
